.form-item {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 3fr 9fr;
  border: thin solid silver;
  border-bottom: none;
  font-weight: bold;
}

.form-label {
  padding-top: 8px;
  text-align: center;
  vertical-align: middle;
  color: white;
  background-color: #FFA10A;
  border-right: thin solid silver;
}

.required {
  padding: 2px;
  font-size: small;
  font-weight: lighter;
  color: #FFA10A;
  width: auto;
  background-color: white;
  border-radius: 4px;
}

.form-input {
  margin: 0px;
  padding: 8px 8px 0px 8px;
  text-align: left;
}

.form-input-message {
  margin: 0px;
  margin-top: -12px;
  padding-bottom: 4px;
  color: red;
  background-color: #FFCCCC;
  font-size: x-small;
  text-align: left;
}

.enabled-button {
  cursor: pointer;
  color: gray;
}

.enabled-button:focus {
  cursor: pointer;
  color: gray;
}

.enabled-button:hover {
  color: #FFA10A;
  border-color: #FFA10A;
  cursor: pointer;
}

.disabled-button {
  cursor: not-allowed;
  color: silver;
}

.disabled-button:focus {
  cursor: not-allowed;
  color: silver;
}

.disabled-button:hover {
  cursor: not-allowed;
  color: silver;
}

.enabled-submit-button {
  height: 60px;
  width: 244px;
  font-size: 20px;
  transition: .2s;
  padding: 0;
  cursor: pointer;
  color: gray;
}

.enabled-submit-button:focus {
  cursor: pointer;
  color: gray;
}

.enabled-submit-button:hover {
  color: #FFA10A;
  border-color: #FFA10A;
  cursor: pointer;
}

.disabled-submit-button {
  height: 60px;
  width: 244px;
  font-size: 20px;
  transition: .2s;
  padding: 0;
  cursor: not-allowed;
  color: silver;
}

.disabled-submit-button:focus {
  cursor: not-allowed;
  color: silver;
}

.disabled-submit-button:hover {
  cursor: not-allowed;
  color: silver;
}

.submit-wrapper {
  border-top: thin solid silver;
  padding-top: 28px;
  text-align: center;
  vertical-align: middle;
}

.button-wrapper {
  padding: 8px;
}

.checkbox-wrapper {
  padding: 8px;
  text-align: center;
  vertical-align: middle;
}

input {
  width: 100%;
}

select {
  width: 100%;
}

textarea {
  width: 100%;
  resize: vertical;
}

input[type=checkbox] {
  display: none;
}

.checkbox {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  padding: 5px 30px;
  position: relative;
  width: auto;
}
.checkbox::before {
  background: #fff;
  border: 1px solid #231815;
  content: '';
  display: block;
  height: 16px;
  left: 5px;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  width: 16px;
}
.checkbox::after {
  border-right: 3px solid #FFA10A;
  border-bottom: 3px solid #FFA10A;
  content: '';
  display: block;
  height: 9px;
  left: 10px;
  margin-top: -7px;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: rotate(45deg);
  width: 5px;
}

input[type=checkbox]:checked + .checkbox::after {
  opacity: 1;
}

.file-none {
  color: gray;
  width: 100%;
  height: 100%;
  min-height: 60px;
  text-align: center;
  vertical-align: middle;
}

.file-registered {
  color: black;
  width: 100%;
  height: 100%;
  min-height: 60px;
  text-align: center;
  vertical-align: middle;
}
