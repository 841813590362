/* footer */

footer {
    width: 100%;
    position: absolute;
    bottom: 0;
}

footer .nav-footer {
    /* background-color: #0A1326; */
    position: relative;
    margin-bottom: 60px;
}

footer .nav-footer .container .row {
    position: absolute;
    padding: 0;
    margin: 0 auto;
    text-align: center;
    max-width: 600px !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

footer .nav-footer .container .row a {
    font-size: 12px;
    color: #000;
    /* color: honeydew; */
}

footer .nav-footer .container .row a:hover {
    border-bottom: 4px solid rgb(255, 193, 94);
    transition: all .3s;
}

.footer_logo_2 {
    display: inline-block;
    vertical-align: top;
    padding: 0 20px;
}

.footer_logos {
    text-align: center;
}

.nav-footer {
    display: flex;
    height: 100px;
}

footer_row {
    overflow: hidden;
    height: 171px;
    display: flex;
    margin: 0px auto;
}

/* フッターのグローバルメニューの各リスト */

footer_row li {
    margin: auto 10px auto 10px;
    list-style: none;
    top: 50%;
    float: left;
    font-size: 14.0px;
    color: rgba(51, 51, 51, 1.0);
    text-align: center;
    line-height: 17.0px;
    width: 25%;
}

/* フッターのロゴ */
.footer_logo {
    float: center;
    width: 78.67px;
    height: 101px;
}

.company {
    text-align: left;
}

.copy-right {
    background-color: #FFA10A;
    width: 100%;
    text-align: center;
    color: white;
}


.line {
    margin: 0 auto;
    width: 50%;
    border-bottom: 2px solid #efefef;
}


.logos {
    margin-top: 40px;
    margin-bottom: 40px;
}