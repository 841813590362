/*
Theme Name: Roce, INC.
Theme URI: 
Description: 
Version: 1.0
Author: Takahiro Miyoshi
Author URI: 
*/

html,
body {
    width: 100%;
    margin: 0 !important;
    -webkit-text-size-adjust: 100%;
    font-family: 'Lato', "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif !important;
}

::selection {
    background: rgba(249, 190, 0, 1.0);
    color: black;
    opacity: 0.9;
}


main {
    padding-bottom: 375px;
    display: block;
}

/* news */

.whats-new .whatsnew {
    bottom: 5px;
    position: absolute;
    width: 80% !important;
    left: 10%;
    box-sizing: border-box;
    max-width: 980px;
    width: 100%;
    background-color: rgba(255, 255, 255, .8);
    border-radius: 15px;
}

.whats-new .whatsnew a,
.whats-new .whatsnew a :hover,
.whats-new .whatsnew dl :hover {
    color: black !important;
}

.whats-new .whatsnew dt {
    margin: 0 0 0 0.3em;
    padding: 0;
    border: 0;
    color: black !important;
    font-weight: normal;
    white-space: nowrap;
    border-right: 3px solid #FFA10A !important;
    padding-right: 25px !important;
    padding-left: 25px !important;
}

.whats-new .whatsnew dd {
    padding-left: 25px !important;
}

.whats-new .whatsnew h3 {
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 20px;
    padding-left: 30px;
    margin-bottom: 0px !important;
}

.modal-inner {
    padding: 8px;
}

/*indexページ */

li {
    list-style: none;
}

a {
    text-decoration: none;
}

div {
    -webkit-text-size-adjust: none;
}

div {
    -webkit-text-size-adjust: none;
}

.scroll {
    position: absolute;
    width: 100%;
    right: 5px;
    bottom: 80px;
}

.scroll a {
    cursor: pointer;
    display: block;
    position: relative;
    color: white;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    text-decoration-color: white;
    background-color: transparent;
    transition: all .3s;
    text-align: center;
}

.scroll a:hover {
    color: white;
}

.scroll span {
    text-align: center;
    margin-bottom: 20px;
    bottom: 10%;
    left: 50%;
    display: block;
    position: relative;
    width: 24px;
    height: 24px;
    margin-left: -12px;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: sdb 2s infinite;
    animation: sdb 2s infinite;
    box-sizing: border-box;
}

@-webkit-keyframes sdb {
    0% {
        -webkit-transform: rotate(-45deg) translate(0, 0);
    }

    20% {
        -webkit-transform: rotate(-45deg) translate(-10px, 10px);
    }

    40% {
        -webkit-transform: rotate(-45deg) translate(0, 0);
    }
}

@keyframes sdb {
    0% {
        transform: rotate(-45deg) translate(0, 0);
    }

    20% {
        transform: rotate(-45deg) translate(-10px, 10px);
    }

    40% {
        transform: rotate(-45deg) translate(0, 0);
    }
}

/* about */

.about {
    background-color: #ef9305;
    width: 100%;
    position: relative;
    vertical-align: top;
    background-image: url("images/about.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    text-align: center;
    box-sizing: border-box;
    z-index: 0;
}

.about:before {
    content: '';
    position: absolute;
    top: 30%;
    left: 0;
    width: 120%;
    height: 70%;
    margin: 3% -10% 0;
    background: white;
    -webkit-transform-origin: left center;
    -ms-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    transform: rotate(-10deg);
    z-index: -1;
    padding-bottom: 125px;
}

.about:after {
    content: '';
    position: absolute;
    top: 30%;
    left: 0;
    width: 120%;
    height: 70%;
    margin: 3% -10% 0;
    -webkit-transform-origin: left center;
    -ms-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: rotate(3deg);
    -ms-transform: rotate(3deg);
    transform: rotate(-1deg);
    z-index: -2;
    opacity: 0.4;
    padding-bottom: 125px;
}

.contents {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    padding-bottom: 30px;
    text-align: center;
}

.contents.text-color-white {
    color: white;
}

.about .about_contents .about_text {
    letter-spacing: .015em;
    text-align: justify;
}

.contents_title_jp {
    text-align: center !important;
}


.content-desc {
    width: 100%;
    max-width: 900px;
}

.system-row {
    border-bottom: 4px solid #959595;
}

.content-desc .desc-text {
    letter-spacing: .015em;
    text-align: left;
    padding: 10px;
    margin: 15px;
    box-sizing: border-box;
    border-left: 2px solid #959595;
    display: inline-block;
}

.contact .content-desc .desc-text-contact span {
    color: #959595;
}


.about .about_contents .about_text_jp {
    letter-spacing: .015em;
    /* color: #F6AA00; */
}

.btn {
    position: relative;
    display: block;
    text-align: center;
    font-size: .9em;
    margin-top: 25px;
}

.btn a {
    background-color: #F9BE00;
    font-size: 16px;
    color: #fff !important;
    overflow: hidden;
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    padding: 20px 30px;
    min-width: 250px;
    border-radius: 35px;
    line-height: 1;
    text-align: center;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12);
    transition: all .3s;
}

.btn a:hover {
    border-color: #F9BE00;
    background-color: rgba(255, 255, 255, 0.95);
    color: #F9BE00 !important;
}

.btn a p {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 38%;
    right: 30px;
    font-size: 18px;
    transform: rotate(45deg);
    border-top: solid 2px;
    border-right: solid 2px;
    border-left: none !important;
    border-bottom: none !important;
}

.index-about,
.index-web,
.index-system {
    margin: 0;
    font-size: 50.0px;
    padding-top: 100px;
}

.linkbtn {
    cursor: pointer;
    position: relative;
    display: block;
    text-align: center;
    font-size: .9em;
}

.linkbtn:hover {
    color: #ef8200;
}

.list-item {
    display: grid;
    grid-template-columns: 2fr 8fr;
    margin-bottom: 20px;
}

.list-title {

}

/* System */

.system {
    height: 100%;
    width: 100%;
    position: relative;
    vertical-align: top;
    background-image: url("images/System.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    text-align: center;
    display: flex;
}

.six.columns {
    margin: 0 !important;
}

@media (min-width: 550px) {

    .six.columns {
        width: 50% !important;
    }
}

.row-eq-height {
    display: flex;
    flex-wrap: wrap;
}

.row-eq-height six.columns {
    flex: 1 0 auto;
}

.system_content_wrap {
    background-color: rgba(255, 255, 255, .75);
    /* width: 50%;
    height: 100%; */
    width: 100%;
}

.web_content_wrap {
    width: 100%;
}

/* WEB */
.inquiry-button {
    height: 60px;
    width: 244px;
    font-size: 20px;
    transition: .2s;
    padding: 0;
}

.inquiry-button:hover {
    color: #ef9305;
    border-color: #ef9305;
    transition: .2s;
}

/* recuruit */

.recruit {
    height: 100%;
    width: 100%;
    position: relative;
    vertical-align: top;
    background-image: url("images/recruit.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;
}

.index-recruit {
    color: white;
    margin: 0;
    font-size: 50.0px;
    padding-top: 100px;
}

button.apply,
.wpcf7-form-control.wpcf7-submit {
    height: 60px;
    width: 244px;
    font-size: 20px;
    transition: .2s;
    padding: 0;
}

button.apply:hover,
.wpcf7-form-control.wpcf7-submit:hover {
    color: #ef9305;
    border-color: #ef9305;
    transition: .2s;
}

/* contact */

span.wpcf7-list-item {
    margin: 0 !important;
}



.contact {
    height: 100%;
    width: 100%;
    position: relative;
    vertical-align: top;
    background-image: url("images/contact.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;
    padding: 70px 0px 70px 0px;
}

.index-contact {
    color: white;
    margin: 0;
    font-size: 50.0px;
    padding-top: 100px;
}

/* トップ */

.main-visual {
    background-color: rgba(0, 0, 0, 0.11);
    background-size: cover;
    top: 0px;
    height: 100vh;
    width: 100%;
    position: relative;
    margin: 0;
    left: 0px;
    overflow: hidden;
}

.heroimage.one {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: 25% 5%;
    /* transition: all 1s; */
    opacity: 0.75 !important;
    font-family: 'object-fit: cover;object-position: 25% 5%;';
    /*IE対策*/
}

.heroimage.two {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: 20% 5%;
    /* transition: all 1s; */
    opacity: 0.75 !important;
    font-family: 'object-fit: cover;object-position: 25% 5%;';
    /*IE対策*/
}

.heroimage.daruma {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: 20% 70%;
    /* transition: all 1s; */
    opacity: 0.75 !important;
    font-family: 'object-fit: cover;object-position: 25% 5%;';
    /*IE対策*/
}

.heroimage_1 {
    background: #000;
}

.slick-items {
    padding: 0px !important;
}

/* top */

.title {
    background-color: rgba(255, 255, 255, 0.0);
    top: 20%;
    height: 365px;
    width: 100%;
    position: absolute;
    left: 10%;
    font-size: 40px;
    pointer-events: none;
}

.title .sub-text {
    background-color: rgba(255, 255, 255, 0.0);
    height: auto;
    width: 100%;
    font-size: 22.0px;
    color: rgba(255, 255, 255, 1.0);
    line-height: 33.0px;
    pointer-events: none;
}

.title .rinen {
    background-color: rgba(255, 255, 255, 0.0);
    top: 0px;
    height: auto;
    width: 100%;
    font-size: 60.0px;
    color: rgba(255, 255, 255, 1.0);
    line-height: 92.0px;
    pointer-events: none;
    font-weight: 600;
}

/* slick dots */

.my-dots {
    display: flex;
    top: 60%;
    position: absolute;
    left: 10%;
    cursor: pointer;
    padding: 0;
    margin: 0;
}

.my-dots li {
    margin-right: 20px;
    list-style: none;
    cursor: pointer;
}

@-moz-document url-prefix() {
    .my-dots li button::-moz-focus-inner {
        border: none;
        height: 5px !important;
        width: 120px !important;
        outline: none;
        cursor: pointer;
        background-image: linear-gradient(-90deg, #FFFFFF, #FFFFFF);
        border: 0;
    }

    .my-dots li.slick-active button::-moz-focus-inner {
        background-image: linear-gradient(-90deg, #FFFFFF, #FFA10A);
        border: none;
        height: 10px !important;
        width: 120px !important;
        outline: none;
        cursor: pointer;
    }
}

.my-dots li button {
    border: none;
    height: 5px !important;
    width: 120px !important;
    outline: none;
    cursor: pointer;
    background-image: linear-gradient(-90deg, #FFFFFF, #FFFFFF);
    pointer-events: none;
}

.my-dots li.slick-active button {
    background-image: linear-gradient(-90deg, #FFFFFF, #FFA10A);
    border: none;
    height: 10px !important;
    width: 120px !important;
    outline: none;
    cursor: pointer;
}

.border-bottom {
    padding-bottom: 10px;
    border-bottom: 2px solid #E95504;
}

/* ローディングロゴ */
@-webkit-keyframes fadeOut {
    to {
        opacity: 0;
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        z-index: -1;
    }
}

@keyframes fadeOut {
    to {
        opacity: 0;
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        z-index: -1;
    }
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.loading {
    position: fixed;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
}

.loading img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
    width: 100px;
    height: auto;
}

.loading.fadeOut {
    -webkit-animation: fadeOut .5s cubic-bezier(.55, 0, .1, 1) both;
    animation: fadeOut .5s cubic-bezier(.55, 0, .1, 1) both
}

.main-wrap.fadeIn {
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;

}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

#loading-wrap {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translateY(-65%) translateX(-65%);
    -webkit-transform: translateY(-65%) translateX(-65%);
}

#loader {
    border: 5px solid;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border-color: orange transparent transparent;
    animation: spin 2s linear infinite;
    color: #FFA10A;
    margin: auto;
}

.six.columns {
    margin: 0 !important;
}

/* お知らせ */
.news {
    bottom: 0%;
    position: absolute;
    /* z-index: 9999; */
    width: 60%;
    left: 10%;
    box-sizing: border-box;
}

.news-text {
    float: left;
    text-align: center;
    position: relative;
    top: 25%;
    margin-right: 10%;
}

.news ul {
    padding: 10px 0;
    max-width: 980px;
    width: 100%;
    background-color: rgba(255, 255, 255, .8);
    border-radius: 15px;
}

.news-dtl {
    white-space: nowrap;
    padding-left: 20px;
    top: 50%;
    vertical-align: middle;
    display: inline-block;
}

.news-dtl.date {
    border-right: 3px solid #FFA10A;
    padding-right: 25px;
}

.news ul li {
    box-sizing: border-box;
    /* padding: 0 40px; */
    text-align: left;
    transition: translateY(0.5);
    margin: 0;
}

.news ul div.slick-track {
    width: 100%;
}

li.slick-slide.slick-current.slick-active {
    margin-bottom: 0rem !important;
}

.news-dt {
    padding: 10px 0;
    max-width: 980px;
    width: 100%;
    background-color: rgba(255, 255, 255, .8);
    border-radius: 15px;
}

.news-date {
    white-space: nowrap;
    padding-left: 20px;
    top: 50%;
    min-width: 100px;
    vertical-align: middle;
    display: inline-block;
}

.news-title {
    white-space: nowrap;
    padding-left: 20px;
    top: 50%;
    vertical-align: middle;
    display: inline-block;
}

.news-content {
    line-height: 2.0em;
    white-space: pre-wrap;
    margin-bottom: 40px;
}

/** 会社概要*/

div {
    -webkit-text-size-adjust: none;
}


/* トップ */
.about-main-visual {
    background-image: url("images/about_top.png");
    background-size: cover;
    background-position: center;
    height: 410px;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.system-main-visual {
    background-image: url("images/System.png");
    background-size: cover;
    background-position: center;
    height: 410px;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.web-main-visual {
    background-image: url("images/System.png");
    background-size: cover;
    background-position: center;
    height: 410px;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.recruit-main-visual {
    background-image: url("images/recruit.png");
    background-size: cover;
    background-position: center;
    height: 410px;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.contact-main-visual {
    background-image: url("images/contact.png");
    background-size: cover;
    background-position: center;
    height: 410px;
    width: 100%;
    position: relative;
    overflow: hidden;
    z-index: -1;
}

.privacy-main-visual {
    background-image: url("images/privacy_header.png");
    background-size: cover;
    background-position: center;
    height: 410px;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.sitemap-main-visual {
    background-image: url("images/sitemap.png");
    background-size: cover;
    background-position: center;
    height: 410px;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.news-main-visual {
    background-image: url("images/news.png");
    background-size: cover;
    background-position: center;
    height: 410px;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.about-main-visual~.container,
.system-main-visual~.container,
.web-main-visual~.container,
.recruit-main-visual~.container,
.contact-main-visual~.container,
.privacy-main-visual~.container,
.sitemap-main-visual~.container,
.news-main-visual~.container {
    max-width: 960px !important;
}

.page-title-wrap {
    height: 100%;
    width: 100%;
    position: absolute;
    font-size: 40px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    top: 10%;
}

.page-title-wrap .page-sub-text {
    color: rgba(255, 255, 255, 1.0);
    text-align: justify;
    text-align: center;
}

.page-title-wrap .page-title {
    color: white;
    font-size: 62.0px;
    text-align: justify;
    text-align: center;
}

.company-description {
    padding: 5px;
    top: 50%;
    width: 100%;
    position: absolute;
    color: white;
    font-size: 18px;
    left: 10%;
    pointer-events: none;
}

/* アイコンたち */

.about_icon {
    padding-top: 20px;
    text-align: center;
    margin-bottom: 100px;
    padding-bottom: 10px;
    border-bottom: 1px solid black;
}

.about_details {
    margin-bottom: 100px;
}

.about_titles {
    font-size: 40px;
    margin-bottom: 30px;
}

.row {
    width: 100% !important;
}

.block-line {
    border-bottom: 2px solid #efefef;
    padding: 0 0 50px;
}

h3 {
    font-size: 16px;
    font-weight: bold;
    margin: 30px 0 26px;
}

.contents-h3 {
    font-size: 16px !important;
    font-weight: bold !important;
    margin: 30px 0 26px !important;
}

.mainTitle {
    color: #404040;
    font-size: 30px;
    font-weight: normal;
    padding: 40px 0 30px;
}

.sub-menu {
    cursor: pointer;
}

#rinen_dtls,
#aisatsu_dtls,
#gaiyou_dtls,
.about_titles,
#rinen_dtls+.row {
    text-align: center;
}

h3.subTitle {
    font-size: 20px;
    font-weight: normal;
    color: #f09401;
    padding: 50px 0 40px;
}

h3.subTitle.form-title {
    font-size: 20px;
    font-weight: normal;
    color: #f09401;
    padding: 50px 0 40px;
}

.main-title {
    padding: 40px 0 30px;
    text-align: center;
}

/* web */

h4.subTitle {
    font-size: 15px;
    font-weight: normal;
    padding: 50px 0 30px;
    color: black;
}

/* recruit */

.comment {
    background-color: #ededed;
    top: 44px;
    clear: both;
    padding: 30px 25px;
    display: none;
}

.comment h4 {
    font-size: 20px;
    padding-bottom: 20px;
}

.comment.active {
    display: block;
}

img.photo.active {
    border: 1px solid #ef8200;
    overflow: hidden;
}

.triangle {
    width: 0;
    height: 0;
    margin-left: 5%;
    border-style: solid;
    border-width: 0 50px 50px 50px;
    border-color: transparent transparent #ededed transparent;
}

.triangle_bosyu {
    width: 0;
    height: 0;
    margin-left: 5%;
    border-style: solid;
    border-width: 0 50px 50px 50px;
    border-color: transparent transparent #ededed transparent;
}

#jobDetails {
    background-color: #ededed;
    padding: 30px;
    margin: 0 0 0;
    clear: both;
    text-align: center;
}

#jobDetails .detail th {
    background-color: #e1e1e1;
    width: 100px;
    padding: 15px 10px;
    border: 1px solid #e1e1e1;
    border-bottom: 1px solid #ededed;
}

#jobDetails .detail td {
    background-color: #fff;
    padding: 12px 10px;
    border: 1px solid #e1e1e1;
}

table {
    width: 100%;
}

.btnEnter {
    text-align: center;
    padding: 50px 0;
}

.shinsotsu-saiyou-2 {
    display: none;
}

.comment-sp {
    display: none;
}

.sp-line {
    display: none;
}

/* システム */

.systemTable dt {
    color: #ef8200;
    margin: 0 0 15px;
    text-align: left;
}

.systemTable dd {
    margin: 0;
    text-align: left;
    padding-right: 10px;
}

.systemTable p {
    text-align: left;
}

.systemLists {
    border: 1px solid #404040;
    width: 100% !important;
    table-layout: fixed;
}

.systemLists th {
    background-color: #ebebeb;
    padding: 10px !important;
    border-top: 0px none;
    text-align: center;
    word-break: break-word;
}

.systemLists td {
    padding: 10px !important;

    word-break: break-word;
}

/* プライバシーポリシー */

h3,
dl,
p,
pi {
    text-align: left;
}

/* contact */

label {
    display: inline !important;
}

table.contact-form td,
table.register-form td {
    border: 1px solid #CCD4E6;
}

table.contact-form th,
table.register-form th {
    text-align: center;
    background-color: #F59018;
    color: #EDF6FD;
    vertical-align: top;
    border: 1px solid #F59018;
}

table.contact-form td,
table.register-form td {
    padding: 15px 15px;
}


table.contact-form input:not(.address),
table.register-form input:not(.address):not(.birthday):not(.education),
table.contact-form textarea,
table.register-form textarea {
    width: 100%;
}

/* pricacy policy */

.privacy-h4 {
    font-size: 16px !important;
    font-weight: bold !important;
    margin: 30px 0 26px !important;
}

/* .privacy-h4~dl {
    font-size: 13px;
} */

/* sitemap */

h3.sitemap {
    text-align: center;
}

/* article */

.article-main-text {
    padding: 60px 10px;
}

.article-title {
    padding-left: 120px;
    padding-right: 120px;
    font-size: 30px !important;
}


/* サイトマップ */
.site-map a {
    color: gray;
    font-size: large;
    cursor: pointer;
    text-decoration: none;
}

.site-map a:hover {
    color: #ef9305;
}

/* responsive */
/* sp landscape mode */
@media screen and (max-width: 896px) and (orientation: landscape) {
    .title .rinen {
        background-color: rgba(255, 255, 255, 0.0);
        top: 0px;
        height: auto;
        width: 100%;
        font-size: 16.0px;
        color: rgba(255, 255, 255, 1.0);
        line-height: 30px;
    }

    .title .sub-text {
        background-color: rgba(255, 255, 255, 0.0);
        height: auto;
        width: 100%;
        font-size: 12px;
        color: rgba(255, 255, 255, 1.0);
        line-height: 20.0px;
    }

    .company-description {
        padding: 5px;
        top: 45%;
        width: 100%;
        position: absolute;
        color: white;
        font-size: 10px;
        left: 10%;
        pointer-events: none;
    }

    .whats-new {
        display: none !important;
    }

    .my-dots {
        display: flex;
        top: 55%;
        position: absolute;
        left: 10%;
        cursor: pointer;
        padding: 0;
    }

    .my-dots li {
        margin-right: 20px;
        list-style: none;
        cursor: pointer;
    }

    .my-dots li button {
        border: none;
        height: 3px !important;
        width: 70px !important;
        outline: none;
        cursor: pointer;
        background-image: linear-gradient(-90deg, #FFFFFF, #FFFFFF);
    }

    .my-dots li.slick-active button {
        background-image: linear-gradient(-90deg, #FFFFFF, #FFA10A);
        border: none;
        height: 7px !important;
        width: 70px !important;
        outline: none;
        cursor: pointer;
    }

    .scroll {
        bottom: 20px !important;
    }
}

@media(max-width: 700px) {

    .about-main-visual~.container,
    .system-main-visual~.container,
    .web-main-visual~.container,
    .recruit-main-visual~.container,
    .contact-main-visual~.container,
    .privacy-main-visual~.container,
    .sitemap-main-visual~.container {
        /* max-width: 960px !important; */
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .title .rinen {
        background-color: rgba(255, 255, 255, 0.0);
        top: 0px;
        height: auto;
        width: 100%;
        font-size: 24.0px;
        color: rgba(255, 255, 255, 1.0);
        line-height: 50px;
    }

    .title .sub-text {
        background-color: rgba(255, 255, 255, 0.0);
        height: auto;
        width: 100%;
        font-size: 11.5px;
        color: rgba(255, 255, 255, 1.0);
        line-height: 20.0px;
    }

    .company-description {
        padding: 5px;
        top: 40%;
        width: 70%;
        position: absolute;
        color: white;
        font-size: 11.5px;
        left: 10%;
        pointer-events: none;
    }

    .my-dots {
        display: flex;
        top: 55%;
        position: absolute;
        left: 10%;
        /* z-index: 999999999; */
        cursor: pointer;
        padding: 0;
    }

    .my-dots li {
        margin-right: 20px;
        list-style: none;
        cursor: pointer;
        /* z-index: inherit; */
    }

    .my-dots li button {
        border: none;
        height: 3px !important;
        width: 70px !important;
        outline: none;
        cursor: pointer;
        background-image: linear-gradient(-90deg, #FFFFFF, #FFFFFF);
    }

    .my-dots li.slick-active button {
        background-image: linear-gradient(-90deg, #FFFFFF, #FFA10A);
        border: none;
        height: 7px !important;
        width: 70px !important;
        outline: none;
        cursor: pointer;
    }

    .content-desc {
        font-size: 13px;
        letter-spacing: .015em;
        text-align: justify;
    }

    .news {
        position: absolute;
        /* z-index: 9999; */
        width: 80%;
        left: 10%;
        box-sizing: border-box;
    }

    .news-dtl {
        font-size: 12px;
    }

    .news-dtl.date {
        border-right: none;
        padding-right: 25px;
    }

    p,
    span {
        font-size: 13px;
        letter-spacing: 0.5px;
    }

    h3 {
        font-size: 1.5em;
    }

    .contents-img {
        width: 100%;
    }

    .page-title-wrap .page-title {
        font-size: 24px;
    }

    .page-title-wrap .page-sub-text {
        font-size: 16px;
    }

    .row.about_icon {
        display: none;
    }

    table {
        font-size: 13px;
    }

    .triangle_bosyu {
        display: none;
    }

    #jobDetails {
        font-size: 13px !important;
    }

    .shinsotsu-saiyou {
        display: none;
    }

    .shinsotsu-saiyou-2 {
        display: inline;
    }

    .commentWrap,
    .triangle {
        display: none;
    }

    .comment-sp {
        background-color: #ededed;
        top: 44px;
        clear: both;
        padding: 30px 25px;
        display: block;
        margin-bottom: 13px;
    }

    img.photo.active {
        border: none;
        overflow: hidden;
    }

    .visual.web img {
        width: 100%;
        height: auto;
    }

    .footer .nav-footer ul li a {
        display: block;
        font-size: 10px !important;
        color: #000000;
        line-height: 14px;
    }

    .article-title {
        padding-left: 120px;
        padding-right: 120px;
        font-size: 16px !important;
    }

    .scroll span {
        text-align: center;
        margin-bottom: 20px;
        bottom: 10%;
        left: 50%;
        display: block;
        position: relative;
        width: 16px;
        height: 16px;
        margin-left: -8px;
        border-left: 1px solid #fff;
        border-bottom: 1px solid #fff;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        -webkit-animation: sdb 2s infinite;
        animation: sdb 2s infinite;
        box-sizing: border-box;
    }
}

/* responsive */
@media (max-width: 600px) {
    .whats-new .whatsnew {
        bottom: 5px;
        position: absolute;
        /* z-index: 9999; */
        width: 80% !important;
        left: 10%;
        box-sizing: border-box;
        max-width: 980px;
        width: 100%;
        background-color: rgba(255, 255, 255, .8);
        border-radius: 15px;
    }

    .whats-new .whatsnew a {
        color: black;
    }

    .whats-new .whatsnew dt {
        margin: 0 0 0 0.3em;
        padding: 0;
        border: 0;
        color: black !important;
        font-weight: normal;
        white-space: nowrap;
        border: none !important;
        padding-right: 10px !important;
        padding-left: 10px !important;
    }

    .whats-new .whatsnew dd {
        padding-left: 20px !important;
    }

    .whats-new .whatsnew h1 {
        margin-bottom: 0px !important;
        padding-left: 15px;
    }

    .scroll {
        position: absolute;
        width: 100%;
        right: 5px;
        bottom: 90px;
        font-size: small;
    }

    table.contact-form,
    table.register-form,
    #jobDetails table {
        border-collapse: collapse !important;
    }

    table.contact-form th,
    table.contact-form td,
    table.register-form th,
    table.register-form td,
    #jobDetails table th,
    #jobDetails table td {
        display: block !important;
    }

    #jobDetails .detail th {
        width: auto;
    }

    main {
        padding-bottom: 563px;
    }
}

/* skelton.cssで隙間ができてしまう問題対策 */

@media (min-width: 550px) {
    .six.columns {
        width: 50% !important;
    }
}

/* IEのみ適用(v10以降) */

@media all and (-ms-high-contrast: none) {
    .company-description {
        padding: 5px;
        top: 60%;
        width: 50%;
        position: absolute;
        color: white;
        font-size: 20px;
        left: 10%;
        pointer-events: none;
    }

    .my-dots {
        display: flex;
        top: 50%;
        position: absolute;
        left: 10%;
        /* z-index: 999999999; */
        cursor: pointer;
        padding: 0;
        margin: 0;
    }
}


@media all and (-ms-high-contrast: none) and (max-width: 700px) {

    .company-description {
        padding: 5px;
        top: 60%;
        width: 50%;
        position: absolute;
        color: white;
        font-size: 11.5px;
        left: 10%;
        pointer-events: none;
    }

}
  