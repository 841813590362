
/* header */
.page-container {
    position: relative;
    min-height: 100vh;
}

.menu-jp {
    display: block;
    font-size: .75em;
    font-weight: normal;
    white-space: nowrap;
}

.menu-en {
    height: 50%;
}

.header {
    background-color: rgba(255, 255, 255, 1.0);
    top: 0px;
    height: 72px;
    width: 100%;
    margin: 0;
    left: 0px;
}

.header-logo_link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 72px;
    align-items: center;
    -ms-flex-align: center;
}

.header-logo_name {
    margin-left: 19px;
    line-height: 1;
    font-weight: 600;
}

/* グローバルメニュー */

.header .row {
    height: 100% !important;
}

.menu {
    height: 100% !important;
}

.menu-logo,
.menu-items {
    height: 100%;
}

#header-logo {
    width: 112px;
    height: 100%;
    padding-left: 24px;
}

.header .menu ul {
    margin: 0 auto;
}

.header .menu ul li {
    /* margin-right: 45px; */
    float: left;
    margin: auto 25px auto 10px;
    list-style: none;
    font-size: 14.0px;
    color: rgba(51, 51, 51, 1.0);
    text-align: center;
}

/* ボーダー */

.header .menu ul li.border {
    width: 1px;
    height: 10px;
    border-right: medium solid #F29600;
}

#nav-pc {
    overflow: hidden;
    /* float: right; */
    height: 100%;
    display: flex;
    margin: auto;
    letter-spacing: 1px;
    float: right;
}

#header {
    background-color: white;
    z-index: 1;
}

.sticky {
    position: fixed !important;
    top: 0;
    width: 100%;
}

.menu label {
    display: none;
}

input#menu {
    display: none;
}

.nav-sp {
    visibility: hidden;
    height: 0;
    touch-action: none;
    line-height: 0;
    transition: 0.2s;
}

#hamburger-menu {
    display: none;
}

/* レスポンシブ */

@keyframes pop-in {
    0% {
        opacity: 0;
        height: 0;
    }

    100% {
        opacity: 1;
        height: 100vh;
    }
}

@keyframes pop-out {
    0% {
        opacity: 1;
        height: 100vh;
    }

    100% {
        opacity: 0;
        height: 0;
    }
}

@media(max-width:1036px) {
    #nav-pc {
        display: none;
    }

    #menu-icon {
        height: 100%;
        width: 24px;
        float: right;
    }

    #header-logo {
        width: 112px;
        height: 100%;
        padding-left: 12px;
    }

    .header {
        height: 56px !important;
    }

    .header .row {
        height: 0px !important;
    }

    .header-logo_link {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 56px;
        align-items: center;
        -ms-flex-align: center;
    }

    .header-logo_name {
        margin-left: 19px;
        line-height: 1;
        font-weight: 600;
    }

    .nav-sp .about_m {
        line-height: 20px;
    }

    .nav-sp li {
        margin: auto 10px auto 10px;
        padding-top: 20px;
        padding-bottom: 20px;
        list-style: none;
        top: 36px;
        font-size: 14.0px;
        color: rgba(51, 51, 51, 1.0);
        text-align: center;
        line-height: 50.0px;
    }

    .nav-sp li:not(:last-child) {
        border-bottom: 0.8px solid #F29600;
    }

    #hamburger-menu {
        display: block;
        cursor: pointer;
        text-align: center;
    }

    .menu-btn {
        color: transparent;
        font: 0/0 a;
        overflow: hidden;
        text-shadow: none;
        cursor: pointer;
        display: block;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        border: none;
        margin: 0;
    }

    .nav-sp.responsive {
        visibility: visible;
        background-color: white;
        top: 56px;
        animation: pop-in .3s ease-out 0s forwards;
        z-index: 99999;
        position: fixed;
        width: 100vw;
        left: 0px;
        touch-action: none;
        overflow: hidden;
    }

    .nav-sp.close {
        background-color: white;
        top: 56px;
        animation: pop-out .3s ease 0s forwards;
        position: fixed;
        width: 100vw;
        left: 0px;
        touch-action: none;
    }
}

/* sp landscape mode */
@media screen and (max-width: 896px) and (orientation: landscape) {
    .nav-sp.responsive {
        overflow: scroll !important;
    }

    #loader{
        display: none;
    }
}