.modal {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2147483647;
    border-radius: 12px;
}

.modal .overlay {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #000;
    opacity: 0.5;
    top: 0;
    left: 0;
}

.modal-wrapper {
    position: absolute;
    max-height: 50%;
    max-width: 80%;
    min-height: 10%;
    min-width: 30%;
    background-color:cadetblue;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.modal-footer {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color :white;
    border-top: 1px solid gray;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.modal-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top: 1px solid gray;
    overflow-x: hidden;
    overflow-y: auto;
    background-color:white;
}

.modal-close {
    position: absolute;
    cursor: pointer;
    top: 5px;
    right: 10px;
    text-decoration: none;
    background-color: white;
}

.modal-list-item {
    padding: 8px;
}

.modal-list-item:hover {
    padding: 8px;
    color: white;
    background-color: #FFA10A;
}

.modal-button-wrapper {
    padding-top: 20px;
    text-align: center;
    vertical-align: middle;
  }
  